import { Content, Header, InfoCard, ItemCardHeader, Page } from '@backstage/core-components';
import {
  ComponentAccordion,
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderLabel } from '@backstage/core-components';
import { NDP_VERSION } from './ndpversion';
import { useUserProfile } from '@backstage/plugin-user-settings';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { TabbedIcons, TabAccordianProps } from './TabbedIcons';
import useSWR from 'swr';
import { ErrorReport } from '../../common';
import { QuickAccessLinks } from '../../types/types';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import Alert from '@mui/material/Alert';

const QUICK_ACCESS_API_URL = '/platform/quickaccesslinks';

const useStyles = makeStyles()(theme => ({
  img: {
    height: '40px',
    width: 'auto',
  },
  customSearchBarContainer: {
    display: 'flex',
    width: '65vw',
    margin: 'auto',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      width: '100%', // ensure the input field itself also stretches to full width
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 3),
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  title: {
    'div > div > div > div > p': {
      textTransform: 'uppercase',
    },
  },
  notchedOutline: {
    borderStyle: 'none!important',
  },
  itemCardGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: '0 8px',
    minWidth: 430,
    maxWidth: 430,
    transition: '0.3s',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 6px 10px 3px rgba(0, 0, 0, .3)',
    },
  },
}));

const TabAccordian: React.FC<TabAccordianProps> = ({ item, tab, onChange }) => {
  const [shouldExpand, setShouldExpand] = useState(item.isExpanded);

  const handleChange = (panel: any) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setShouldExpand(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={shouldExpand} onChange={handleChange(!shouldExpand)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TabbedIcons data={item.links as any} tab={tab} onChange={onChange} />
      </AccordionDetails>
    </Accordion>
  );
};

const QuickAccess = () => {
  const discoveryApi = useApi(discoveryApiRef);
  const [backendUrl, setBackendUrl] = useState<string | null>(null);
  const { classes } = useStyles();
  const [tab, setTab] = useState(0);

  const fetchApi = useApi(fetchApiRef);

  useEffect(() => {
    const fetchBackendUrl = async () => {
      const url = await discoveryApi.getBaseUrl('backend');
      setBackendUrl(url);
    };
    fetchBackendUrl();
  }, [discoveryApi]);

  const fetcher = (url: RequestInfo) => fetchApi.fetch(url).then(res => res.json());

  const { data: configData, error: configError } = useSWR(
    `${backendUrl?.replace('/backend', '')}${QUICK_ACCESS_API_URL}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const fetchJsonContent = useFetchJsonFromGHRepo();
  const [githubData, setGithubData] = useState<QuickAccessLinks[] | null>(null);

  useEffect(() => {
    if (configData) {
      fetchJsonContent<any>(configData.gitRepoUrl, configData.branch, configData.filePath)
        .then(data => setGithubData(data))
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching GitHub content:', error);
        });
    }
  }, [configData, fetchJsonContent]);

  const isLoading = !configData || !githubData;

  if (configError) {
    return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!githubData || githubData.length === 0) {
    return <ErrorReport title="Could not fetch data." errorText="Unknown error" />;
  }

  const onChange = (_: any, value: number) => {
    setTab(value);
  };

  return (
    <InfoCard title="Quick Access" noPadding className={classes.title}>
      {githubData?.map(item => {
        if (!item) {
          // eslint-disable-next-line no-console
          console.error('Encountered a null or undefined item in data array');
          return null;
        }

        if (!item.isTabbed) {
          return (
            <HomePageToolkit
              key={item.title}
              title={item.title}
              tools={item.links[0].values.map(link => ({
                ...link,
                icon: <img className={classes.img} src={link.iconUrl} alt={link.label} />,
              }))}
              Renderer={
                item.isExpanded
                  ? props => <ComponentAccordion expanded {...props} />
                  : props => <ComponentAccordion {...props} />
              }
            />
          );
        }
        return <TabAccordian key={item.title} item={item} tab={tab} onChange={onChange} />;
      })}
    </InfoCard>
  );
};

export const SupportRequests = () => {
  const { classes } = useStyles();
  const [shouldExpand, setShouldExpand] = useState(true);

  const handleChange = (panel: any) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setShouldExpand(isExpanded ? panel : false);
  };
  return (
    <div style={{ width: '100%' }}>
      <Accordion
        style={{ width: '100%' }}
        expanded={shouldExpand}
        onChange={handleChange(!shouldExpand)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccessibilityNewIcon style={{ marginRight: '8px' }} />
          <Typography>Support and Access Requests</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: 'auto' }}>
          <Grid className={classes.itemCardGrid}>
            <Card className={classes.card}>
              <CardMedia>
                <ItemCardHeader title="Request Support" subtitle="Engage with the Platform Team" />
              </CardMedia>
              <CardContent>
                <div>
                  Need help?{' '}
                  <u>
                    <a
                      // editorconfig-checker-disable
                      href="https://dev.azure.com/nintex/Nintex/_wiki/wikis/Nintex.wiki/8454/Engaging-the-Platform-and-Infrastructure-team"
                      // editorconfig-checker-enable
                      target="_new"
                    >
                      Engage the Platform Team
                    </a>
                  </u>{' '}
                  by raising a work request.
                </div>
                <br />
                <div>
                  For K2 DevOps, click{' '}
                  <u>
                    <a
                      // editorconfig-checker-disable
                      href="https://tfs.k2.com/tfs/Labs/K2%202014/_workitems/create/Requirement?templateId=a3af1ccf-0f39-468d-a56a-4e06dd507979"
                      // editorconfig-checker-disable
                      target="_new"
                    >
                      here.
                    </a>
                  </u>{' '}
                </div>
              </CardContent>
              <CardActions>
                <a
                  href="https://dev.azure.com/nintex/Nintex/_workitems/create/Support?templateId=a9457568-1854-4fbc-8403-714cc7600614&ownerId=827421dc-93c6-4121-8a53-6a7fad6a5197"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="primary">Raise work request</Button>
                </a>
              </CardActions>
            </Card>

            <Card className={classes.card}>
              <CardMedia>
                <ItemCardHeader title="Engineer Onboarding" subtitle="Welcome to Nintex!" />
              </CardMedia>
              <CardContent>
                <div>
                  To onboard a new team member check out the information about the{' '}
                  <u>
                    <a
                      href="https://nintex.sharepoint.com/sites/hr/SitePages/Human%20Resources%20and%20Talent%20Acquisition%20Wikis.aspx"
                      target="_new"
                    >
                      HR onboarding process
                    </a>
                  </u>
                  , and{' '}
                  <u>
                    <a
                      href="https://dev.azure.com/nintex//Nintex/_wiki/wikis/Nintex.wiki/8226/Access-Requests"
                      target="_new"
                    >
                      how to request access to the R+D systems.
                    </a>
                  </u>
                </div>
              </CardContent>
              <CardActions>
                <a
                  href="https://nintex-prodops.workflowcloud.com/forms/f8d5a120-736b-4b16-ba13-91ee85a53fa1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="primary">Raise Access Request</Button>
                </a>
              </CardActions>
            </Card>

            <Card className={classes.card}>
              <CardMedia>
                <ItemCardHeader title="Relevant Slack Channels" subtitle="Ask on Slack!" />
              </CardMedia>
              <CardContent>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>NSP (Nintex Shared Platform)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03M0K474PR"
                  >
                    #rnd_platform_nsp
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>NDP (Nintex Developer Portal)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03DKPU4DPS"
                  >
                    #rnd_platform_ndp
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>TechRadar</span>
                  <Link
                    style={{ minWidth: '150px' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C048A445LN6"
                  >
                    #rnd_tech-radar
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>ITDs (Important Technical Decisions)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C02M3NTQ8F5"
                  >
                    #rnd_itds
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>GitHub Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03SYL4LG1G"
                  >
                    #github_help
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>ADO Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C02M6LZQVF1"
                  >
                    #help_ado
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>API Standards Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C01NDGFT7TP"
                  >
                    #help_api-standards
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>DevOps Practice</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/CJSLSVCSX"
                  >
                    #rnd_devops_practice
                  </Link>
                </div>
              </CardContent>
              <CardActions/>
            </Card>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const HomePage = () => {
  const { classes } = useStyles();

  const { profile } = useUserProfile();
  const displayName = profile?.displayName || '';
  const firstName = displayName.split(/(\s+)/)[0];

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={`Welcome back to NDP, ${firstName}!`}>
          <HeaderLabel label="Owned by" value="Platform Team" url="mailto:platform@nintex.com" />
          <HeaderLabel label="Version" value={NDP_VERSION} />
        </Header>
        <Content>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Box className={classes.customSearchBarContainer}>
              <HomePageSearchBar placeholder="Search" />
            </Box>

            <SupportRequests />

            <Grid container spacing={0}>
              <Grid item xs={12} md={7} style={{ paddingRight: '16px' }}>
                <QuickAccess />
              </Grid>
              <Grid item xs={12} md={5} style={{ paddingLeft: '16px' }}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
