import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React from "react";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  greyText: {
      color: theme.palette.text.secondary,
  },
  img: {
    height: '64px',
    width: '64px',
    marginBottom: theme.spacing(0.5),
    borderRadius: '50%',            // Makes the icon circular.
    padding: theme.spacing(1),      // Provides spacing between the icon and its circular background.
    backgroundColor: '#333333',  // Adds a black background.
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  iconItem: {
      marginTop: theme.spacing(3)
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
        '& $textUnderline': {
            textDecoration: 'underline'
        }
    },
    overflow: 'hidden',   // Ensures that the circular icon doesn't spill over.
  },
  textUnderline: {
    textDecoration: 'none',
    marginTop: theme.spacing(0.5)
  }
}));

interface IconValue {
    iconUrl: string;
    label: string;
    url: string;
  }

  interface TabData {
    tab: string;
    values: IconValue[];
  }

  export type TabAccordianProps = {
    item: {
      isExpanded?: boolean;
      title: string;
      links: {}
    };
    tab: any;
    onChange: (_: any, value: number) => void

  };

  interface TabbedIconsProps {
    data: TabData[];
    tab: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  }

  export const TabbedIcons: React.FC<TabbedIconsProps> = ({ data, tab, onChange }) => {
    const selectedTab = data[tab];
    const { classes } = useStyles();

    return (
      <div>
        <Tabs value={tab} onChange={onChange}>
          {data?.map((item, index) => (
            <Tab label={item.tab} key={index} />
          ))}
        </Tabs>
        <Grid container direction="row" alignItems="center" spacing={1}>
          {selectedTab?.values?.map((value, vIndex) => (
            <Grid item key={vIndex} className={classes.iconItem}>
              <a href={value?.url} target="_blank" rel="noopener noreferrer" className={classes.iconContainer}>
                <img className={classes.img} src={value?.iconUrl} alt={value?.label} />
                <Typography variant="caption" color="textSecondary" className={classes.textUnderline}>{value?.label}</Typography>
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
