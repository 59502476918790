import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const maintenanceCommsPlugin = createPlugin({
  id: 'maintenance-comms',
  routes: {
    root: rootRouteRef,
  },
});

export const MaintenanceCommsPage = maintenanceCommsPlugin.provide(
  createRoutableExtension({
    name: 'MaintenanceCommsPage',
    component: () =>
      import('./components/MaintenanceCommsHomePage').then(m => m.MaintenanceCommsHomePage),
    mountPoint: rootRouteRef,
  }),
);
