import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useState, useEffect, } from 'react';
import { PrefixedTextProps } from './schema';

export { PrefixedTextSchema } from './schema';

export const PrefixedTextField = (props: PrefixedTextProps) => {
  const {
    idSchema,
    schema: { title = 'Prefixed Textfield', description = 'Textfield prepended with domain value' },
    required,
    onChange,
  } = props;

  const {formData} = props.formContext
  const [text, setText] = useState("");
  const [prefix, setPrefix] = useState("");

  // Extract and set the prefix whenever the domain changes
  useEffect(() => {
    if (formData?.domain) {
      const domainValues = formData.domain.toString().split(',');
      if (domainValues.length > 1) {
        setPrefix(`${domainValues[0]}-`);
      }
    }
  }, [formData?.domain]);

  // Watch for changes in text and prefix and call onChange with the combined value
  useEffect(() => {
    onChange(`${prefix}${text}`);
  }, [prefix, text, onChange]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <TextField
      id={idSchema?.$id}
      label={title}
      helperText={description}
      margin="normal"
      required={required}
      value={text}
      onChange = {handleChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
      }}
    />
  );
};
