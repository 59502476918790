import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { configCatApiRef } from '@internal/plugin-config-cat';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Content, Header, Page } from '@backstage/core-components';
import { DateTime } from 'luxon';

export const SavingsLoader = () => {
  return (
    <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Skeleton variant="text" width="45%" height={200} />
      <Skeleton variant="text" width="90%" height={100} />
      <Skeleton variant="text" width="45%" height={16} />
      <Skeleton variant="text" width="45%" height={16} />
      <Skeleton variant="text" width="45%" height={16} />
      <Skeleton variant="text" width="45%" height={16} />
      <Skeleton variant="text" width="45%" height={16} />
    </Container>
  );
};

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

type SavingsTrackerOptions = {
  amountPledged: number;
  goal: number;
  description: string;
  pledgeLink: string;
  endDate: string;
};

export const SavingsTracker = ({
  amountPledged,
  goal,
  description,
  pledgeLink,
  endDate,
}: SavingsTrackerOptions) => {
  const [progress, setProgress] = useState<number>(0);
  const [timeDiff, setTimeDiff] = useState<string>('None');
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    window.setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);

  const theme = useTheme();

  useEffect(() => {
    const goalProgress = Math.floor((amountPledged / goal) * 100);

    setProgress(goalProgress);
  }, [amountPledged, goal]);

  useEffect(() => {
    const trackingEnd = DateTime.fromISO(endDate);
    const now = DateTime.now();
    const diff = trackingEnd.diff(now, ['days', 'hours', 'minutes', 'seconds']);

    if (diff.seconds < 0) {
      return;
    }

    setTimeDiff(diff.toFormat("d 'days' h 'hours and' m 'minutes' s 'seconds'"));
  }, [endDate, time]);

  return (
    <Grid container style={{ flexDirection: 'column' }} padding={2}>
      <Container style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="savings_july_img.png"
          alt="Reduce our COGS: Azure or otherwise!"
          style={{ width: '50%', alignSelf: 'center', borderRadius: theme.shape.borderRadius }}
        />
      </Container>
      <Card
        variant="elevation"
        elevation={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(2),
        }}
      >
        <CardContent>
          <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2">$ {amountPledged} Pledged</Typography>
            <Typography variant="body2">Goal: $ {goal}</Typography>
          </Container>

          <Container>
            <LinearProgressWithLabel value={progress} />
          </Container>

          <Container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: theme.spacing(2),
            }}
          >
            <Typography variant="body2">Time remaining: {timeDiff}</Typography>
            <Button variant="contained" target="_blank" href={pledgeLink}>
              Pledge
            </Button>
          </Container>
        </CardContent>
      </Card>

      <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Markdown>{description}</Markdown>
      </Container>
    </Grid>
  );
};

export const SavingsPage = () => {
  const configCatApi = useApi(configCatApiRef);

  const { value, loading } = useAsync(async () => {
    const config = await configCatApi.getValue<string>('savings-tracker-config', '{}');

    return JSON.parse(config);
  }, [configCatApi]);

  if (loading) {
    return <SavingsLoader />;
  }

  return (
    <Page themeId="home">
      <Header title={value?.title ?? 'Cost Savings Tracker'}/>
      <Content>
        <SavingsTracker
          amountPledged={value.amountPledged}
          description={value.description}
          endDate={value.endDate}
          goal={value.goal}
          pledgeLink={value.pledgeLink}
        />
      </Content>
    </Page>
  );
};
