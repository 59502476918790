import { Manifest, QueryManifestResponse } from '../types/trebuchet';
import { Entity } from '@backstage/catalog-model';
import { ConfigApi, createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { FILTER_PROPERTIES } from '../constants/filter-properties';

export const trebuchetBackendApiRef = createApiRef<TrebuchetBackendApi>({
  id: 'plugin.trebuchet.backend.service',
});

export type TrebuchetBackendApi = ReturnType<typeof trebuchetBackendApi>;

type TrebuchetBackendApiOptions = {
  configApi: ConfigApi;
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

export const trebuchetBackendApi = ({ discoveryApi, fetchApi }: TrebuchetBackendApiOptions) => {
  const fetchChangeManifests = async (query: URLSearchParams) => {
    const BASE_URL = await discoveryApi.getBaseUrl('proxy');
    const PROXY_PATH = '/trebuchet';
    const MANIFEST_ENDPOINT = '/api/manifest';
    const endpoint = [BASE_URL, PROXY_PATH, MANIFEST_ENDPOINT, '?', query.toString()].join('');

    const response = await fetchApi.fetch(endpoint);
    if (response.status !== 200) {
      throw response;
    }

    const data: QueryManifestResponse = await response.json();

    return data;
  };

  const fetchFilterProps = async () => {
    const BASE_URL = await discoveryApi.getBaseUrl('proxy');
    const PROXY_PATH = '/trebuchet';
    const MANIFEST_PROPERTIES_ENDPOINT = '/api/manifest/properties';
    const query = `?properties=${JSON.stringify(FILTER_PROPERTIES)}`;

    const endpoint = [BASE_URL, PROXY_PATH, MANIFEST_PROPERTIES_ENDPOINT, query].join('');

    const response = await fetchApi.fetch(endpoint);
    if (response.status !== 200) {
      throw response;
    }

    return response.json();
  };

  const fetchChangeManifestById = async (manifestId: string) => {
    const BASE_URL = await discoveryApi.getBaseUrl('proxy');
    const PROXY_PATH = '/trebuchet';
    const MANIFEST_ENDPOINT = `/api/manifest/${manifestId}`;
    const endpoint = [BASE_URL, PROXY_PATH, MANIFEST_ENDPOINT].join('');

    const response = await fetchApi.fetch(endpoint);
    if (response.status !== 200) {
      throw response;
    }

    const data: Manifest = await response.json();

    return data;
  };

  const fetchReport = async (manifest: Manifest) => {
    const BASE_URL = await discoveryApi.getBaseUrl('proxy');
    const PROXY_PATH = '/trebuchet';
    const MANIFEST_ENDPOINT = `/api/manifest/${manifest._id}/scan-report`;
    const endpoint = [BASE_URL, PROXY_PATH, MANIFEST_ENDPOINT].join('');

    const response = await fetchApi.fetch(endpoint);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const filename = manifest.scanResult?.report?.url?.split('/').pop()

    if (!filename) {
      return {
        url,
        filename: `scan-report.${manifest._id}.pdf`,
      };
    }

    return {
      url,
      filename,
    };
  };

  return {
    fetchChangeManifests,
    fetchFilterProps,
    fetchChangeManifestById,
    fetchReport,
  };
};

export const isTrebuchetAvailable = (entity: Entity) => {
  const hasSlug = !!entity.metadata.annotations?.['github.com/project-slug'];

  return hasSlug;
};
