import { DiscoveryApi, createApiRef, FetchApi, StorageApi } from '@backstage/core-plugin-api';
import { SettingValue } from 'configcat-js';
import { JsonValue } from '@backstage/types';
import { CONFIG_CAT_FLAGS_STORAGE_KEY, REFRESH_INTERVAL } from '../constants/api';

type ConfigCatApiOptions = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  storageApi: StorageApi;
};

export const configCatApiRef = createApiRef<ConfigCatApi>({
  id: 'plugin.config-cat.service',
});

export type ConfigCatApi = ReturnType<typeof configCatApi>;

export const configCatApi = ({ discoveryApi, fetchApi, storageApi }: ConfigCatApiOptions) => {
  const getValueSource = async <T extends SettingValue>(
    key: string,
    defaultValue: T,
  ): Promise<T> => {
    const BASE_URL = await discoveryApi.getBaseUrl('config-cat');

    const url = new URL(`${BASE_URL}/values/${key}`);

    url.searchParams.set('defaultValue', JSON.stringify(defaultValue));

    const response = await fetchApi.fetch(url.toString());

    const { value } = await response.json();

    return (value as T) ?? defaultValue;
  };

  const getValue = async <T extends SettingValue>(key: string, defaultValue: T): Promise<T> => {
    const { value } = storageApi.snapshot(CONFIG_CAT_FLAGS_STORAGE_KEY);

    if (!value || !value[key] === null || !value[key] === undefined) {
      return getValueSource(key, defaultValue);
    }

    return value[key];
  };

  const getFeatureFlag = async (flag: string) => {
    return getValue(flag, false);
  };

  const getAllValues = async () => {
    const BASE_URL = await discoveryApi.getBaseUrl('config-cat');

    const url = new URL(`${BASE_URL}/values`);

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<JsonValue>;
  };

  const observer = storageApi.observe$(CONFIG_CAT_FLAGS_STORAGE_KEY);

  const subscribe = (callback: (value: JsonValue | undefined) => void) => {
    observer.subscribe(value => {
      callback(value.value);
    });
  };

  const refreshStorage = async () => {
    const values = await getAllValues();

    await storageApi.set(CONFIG_CAT_FLAGS_STORAGE_KEY, values);
  };

  setInterval(async () => {
    refreshStorage();
  }, REFRESH_INTERVAL);

  refreshStorage();

  return {
    getFeatureFlag,
    getValue,
    subscribe,
  };
};
