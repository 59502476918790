import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Header, Page } from '@backstage/core-components';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useApi, configApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { useUserProfile } from '@backstage/plugin-user-settings';

const useStyles = makeStyles()(theme => {
  return {
    grid: {
      display: 'flex',
      flexDirection: 'column',
      margin: '16px 0px',
      flex: 1,
    },
    card: {
      maxWidth: '90%',
      marginBottom: '8px',
      // Change the color of links inside card elements
      '& a': {
        color: '#1976d2',
      },
    },
    human: {
      alignSelf: 'end',
    },
    ai: {
      '&&': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? /* Nintex logo dark color => */ '#03161E'
            : 'lch(84 40.26 59.52)', // not a valid color, making ugly to get feedback
      },
    },
    prompt: {
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    about: {
      fontStyle: 'italic',
    },
    link: {
      color: '#1976d2',
    },
    loading: {
      textAlign: 'center',
      display: 'block',
    },
  };
});

export const ChatPage = () => {
  const { classes } = useStyles();
  const config = useApi(configApiRef);
  const fetchApi = useApi(fetchApiRef);
  const { profile } = useUserProfile();

  const promptInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (promptInputRef.current) {
      promptInputRef.current.focus();
    }
  }, []);

  // Loading state
  const [loading, setLoading] = useState(false);

  // An array of objects that represent the cards on the page
  const [cards, setCards] = useState<JSX.Element[]>([]);

  // For the info alert
  const [open, setOpen] = React.useState(true);

  const [chatHistory, setChatHistory] = useState<Message[]>([]);

  const messagesEndRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [cards]);

  class Message {
    content: string;
    role: string;
    sources: string[] = [];
    titles: string[] = [];

    constructor() {
      this.content = '';
      this.role = '';
    }
  }

  // Add a message to the cards
  const addMessage = (message: Message) => {
    const validSources = message.sources.filter(source => source.startsWith('http'));

    const titles = message.titles || [];
    const rendered_link: string[] = [];

    const sources = validSources.map((source, sourceIndex) => {
      if (rendered_link.includes(source)) {
        return <></>;
      }

      rendered_link.push(source);
      return (
        <li key={sourceIndex}>
          <a href={source} target="_blank">
            {titles[sourceIndex] ? titles[sourceIndex] : source}
          </a>
        </li>
      );
    });

    const messageCard = (
      <Card
        className={`${classes.card} ${message.role === 'human' ? classes.human : classes.ai}`}
        key={cards.length}
      >
        <CardContent>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.content}</ReactMarkdown>
          {sources}
        </CardContent>
      </Card>
    );

    // Add to chat history
    chatHistory.push(message);
    setChatHistory([...chatHistory]);

    // Add the new card to the cards array
    cards.push(messageCard);
    setCards([...cards]);
  };

  const processPrompt = (prompt: string) => {
    // Clear prompt
    (document.getElementById('prompt') as HTMLInputElement).value = '';

    // Create the human message
    const humanMessage = new Message();
    humanMessage.content = prompt;
    humanMessage.role = 'human';

    // Add to message history
    addMessage(humanMessage);

    // Disable prompt until a response is received
    (document.getElementById('prompt') as HTMLInputElement).disabled = true;

    // Show loading
    setLoading(true);

    // Send prompt to backend
    const backendUrl = config.getString('backend.baseUrl');

    fetchApi
      .fetch(`${backendUrl}/api/proxy/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: chatHistory,
          model: 'gpt-35-turbo',
          question: prompt,
          temperature: 0.2,
          user: profile.email,
        }),
      })
      .then(response => response.json())
      .then(data => {
        // Hide loading
        setLoading(false);

        // Create the ai message
        const aiMessage = new Message();
        aiMessage.content = data.answer;
        aiMessage.role = 'ai';
        aiMessage.sources = data.sources;
        aiMessage.titles = data.titles;

        // Add to message history
        addMessage(aiMessage);

        // Re-enable input box
        (document.getElementById('prompt') as HTMLInputElement).disabled = false;

        // Refocus on the prompt input
        if (promptInputRef.current) {
          promptInputRef.current.focus();
        }
      })
      .catch(error => {
        // Ensure loading is hidden and input is re-enabled in case of error as well
        // eslint-disable-next-line no-console
        console.error('Error:', error);

        // Hide loading
        setLoading(false);

        // Re-enable input box
        (document.getElementById('prompt') as HTMLInputElement).disabled = false;

        // Refocus on the prompt input
        if (promptInputRef.current) {
          promptInputRef.current.focus();
        }
      });
  };

  const handlePromptChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Do something when Enter key is pressed
      processPrompt((event.target as HTMLInputElement).value);
    }
  };

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Platty"/>
        <Content className={classes.content}>
          {open && (
            <Alert severity="info" className={classes.about} onClose={() => setOpen(false)}>
              Welcome to Platty [Beta] - your AI assistant for all things Nintex Engineering! We're
              eager to hear from you! Please share your thoughts and suggestions in our{' '}
              <a
                href="https://nintex.slack.com/archives/C03DKPU4DPS"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                #rnd_platform_ndp Slack channel
              </a>
              . Got a Nintex-related question? Dive right in and ask away! Welcome aboard the future
              of AI-assisted 'Platform Engineering'!"
            </Alert>
          )}
          <Grid className={classes.grid}>{cards}</Grid>
          {loading && (
            <p className={classes.loading} id="loading">
              Loading...
            </p>
          )}
          <TextField
            className={classes.prompt}
            id="prompt"
            label="Ask anything here!"
            variant="outlined"
            onKeyUp={handlePromptChange}
            inputRef={promptInputRef}
          />
          <div ref={messagesEndRef} />
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
